<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            @ok="submit"
            size="sm"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Capture Ticket</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="submit">
                        <b-row>
                            <b-col cols="12" md="5">
                                <b-form-group label-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="Season" rules="">
                                        <b-form-select
                                            id="season_id"
                                            name="season_id"
                                            size="sm"
                                            v-model="ticket.season_id"
                                            disabled
                                        >
                                            <b-form-select-option
                                                v-for="season in seasons"
                                                :key="season.id"
                                                :value="season.id"
                                            >
                                                {{ season.startYear }}/{{ season.endYear }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="client.type !== 0">
                            <b-col cols="12">
                                <b-form-group label-for="buyer_id" label="Buyer">
                                    <validation-provider #default="{ errors }" name="Buyer" rules="">
                                        <b-form-select
                                            id="buyer_id"
                                            name="buyer_id"
                                            size="sm"
                                            v-model="ticket.buyer_id"
                                            disabled
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="buyer in buyers"
                                                :key="buyer.id"
                                                :value="buyer.id"
                                            >
                                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.buyer_id">{{ serverErrors.buyer_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row
                            v-for="(selected, index) in formSelections"
                            :key="selected.level"
                        >
                            <b-col cols="12">
                                <b-form-group :label-for="selected.level" :label="selected.label">
                                    <validation-provider #default="{ errors }" :name="selected.label" rules="">
                                        <b-form-select
                                            :id="selected.level"
                                            :name="selected.level"
                                            size="sm"
                                            :state="errors.length > 0 ? false : null"
                                            v-model="selected.model"
                                            @change="changeFormSelection(selected.model, index)"
                                            disabled
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="location in selected.locations"
                                                :key="location.id"
                                                :value="location"
                                            >
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="5">
                                <b-form-group labe-for="code" label="Market CODE">
                                    <validation-provider #default="{ errors }" name="Market CODE" rules="">
                                        <b-form-select
                                            id="code"
                                            name="code"
                                            size="sm"
                                            v-model="ticket.market_id"
                                            @change=""
                                            disabled
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="market in markets"
                                                :key="market.id"
                                                :value="market.id"
                                            >
                                                {{ market.code.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="7">
                                <b-form-group labe-for="name" label="Market Center">
                                    <validation-provider #default="{ errors }" name="Market Center" rules="">
                                        <b-form-select
                                            id="name"
                                            name="name"
                                            size="sm"
                                            v-model="ticket.market_id"
                                            @change=""
                                            disabled
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="market in markets"
                                                :key="market.id"
                                                :value="market.id"
                                            >
                                                {{ market.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="5">
                                <b-form-group labe-for="sale_id" label="Sale #">
                                    <validation-provider #default="{ errors }" name="Sale #" rules="">
                                        <b-form-select
                                            id="sale_id"
                                            name="sale_id"
                                            size="sm"
                                            v-model="ticket.sale_id"
                                            @change=""
                                            disabled
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="sale in sales"
                                                :key="sale.id"
                                                :value="sale.id"
                                            >
                                                {{ sale.number }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="7" v-if="client.type !== 2">
                                <b-form-group labe-for="owner_id" label="Grower #">
                                    <validation-provider #default="{ errors }" name="Grower #" rules="">
                                        <b-form-input
                                            ref="refOwner"
                                            id="owner_id"
                                            name="owner_id"
                                            v-model="owner"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            autofocus
                                            @keyup.enter.prevent="validateGrower($event)"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.owner_id">{{ serverErrors.owner_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="7" v-if="client.type === 2">
                                <b-form-group labe-for="owner_id" label="Society">
                                    <validation-provider #default="{ errors }" name="Society" rules="">
                                        <b-form-select
                                            ref="refOwner"
                                            id="owner_id"
                                            name="owner_id"
                                            size="sm"
                                            v-model="owner"
                                            @change="validateSociety($event)"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="society in societies"
                                                :key="society.id"
                                                :value="society.id"
                                            >
                                                {{ society.code.toUpperCase() }}: {{ society.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.owner_id">{{ serverErrors.owner_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="5">
                                <b-form-group labe-for="mass" label="Weight(KGs)">
                                    <validation-provider #default="{ errors }" name="Weight(KGs)" rules="">
                                        <b-form-input
                                            ref="refMass"
                                            id="mass"
                                            name="mass"
                                            v-model="ticket.mass"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            type="number"
                                            @keyup.enter.prevent="validateMass()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.mass">{{ serverErrors.mass[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="7">
                                <b-form-group labe-for="grade_id" label="TTB Grade">
                                    <validation-provider #default="{ errors }" name="TTB Grade" rules="">
                                        <b-form-input
                                            ref="refGrade"
                                            id="grade_id"
                                            name="grade_id"
                                            v-model="grade"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            @keyup.enter.prevent="validateGrade()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.grade_id">{{ serverErrors.grade_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="barcode" label="Barcode">
                                    <validation-provider #default="{ errors }" name="Barcode" rules="">
                                        <b-form-input
                                            ref="refBarcode"
                                            id="barcode"
                                            name="barcode"
                                            v-model="barcode"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            @keyup.enter.prevent="validateBarcode()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.barcode">{{ serverErrors.barcode[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row >
                            <b-col cols="12">
                                Captured: 
                                <span v-if="client.type !== 2">
                                    {{ totalCaptured.length }}/{{ totalRecords }}
                                </span>
                                <span v-if="client.type === 2">
                                    {{ totalRecords }}
                                </span>
                            </b-col>
                        </b-row>

                        <!-- <b-card>
                            {{ ticket }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select
                            size="sm"
                            v-model="ticket.season_id"
                            @change="changeSeason()"
                            disabled
                        >
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="6" md="3" v-if="client.type !== 0">
                        <label>Buyer</label>
                        <b-form-select
                            size="sm"
                            v-model="ticket.buyer_id"
                            @change="changeBuyer()"
                            :disabled="buyers.length === 1"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="buyer in buyers"
                                :key="buyer.id"
                                :value="buyer.id"
                            >
                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>Market</label>
                        <b-form-select
                            size="sm"
                            v-model="ticket.market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>Sale #</label>
                        <b-form-select
                            size="sm"
                            v-model="ticket.sale_id"
                            @change="changeSale()"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="sale in sales"
                                :key="sale.id"
                                :value="sale.id"
                            >
                                {{ sale.number }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <!-- {{ ticket }} -->
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Capture Ticket</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(owner)="data">
                    <span v-if="client.type === 2 && data.item.owner">{{ data.item.owner.code.toUpperCase() + ': ' + data.item.owner.name.toUpperCase() }}</span>
                    <span v-if="client.type !== 2 && data.item.owner">{{ data.item.owner.registrationNumber }}</span>
                </template>
                <template #cell(capturedBy)="data">
                    <span v-if="data.item.capturedBy">{{ data.item.capturedBy.firstName.substring(0, 1).toUpperCase() + data.item.capturedBy.firstName.substring(1).toLowerCase() }} {{ data.item.capturedBy.lastName.substring(0, 1).toUpperCase() + data.item.capturedBy.lastName.substring(1).toLowerCase() }}</span>
                </template>

                <template #cell(updated_at)="data">
                    <span class="text-nowrap">
                      {{ data.item.updated_at | moment('DD/MM/YYYY HH:mm:ss') }}
                    </span>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted, computed } from '@vue/composition-api'
    import ticketsStoreModule from '@/views/cromis/sales/tickets/ticketsStoreModule'
    import useTicketsList from '@/views/cromis/sales/tickets/useTicketsList'
    import moment from 'moment'

    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},
        setup(props, context) {
            const dataForm = ref(null)
            const form = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref(null)
            const selections = ref([])
            const formSelections = ref([])
            const levels = ref(null)
            const children = ref([])
            const seasons = ref([])
            const buyers = ref([])
            const markets = ref([])
            const sales = ref([])
            const grades = ref([])
            const growers = ref([])
            const registrationNumbers = ref([])
            const societies = ref([])
            const tickets = ref([])
            const ourTickets =  ref([])

            const owner = ref(null)
            const refOwner = ref(null)
            const refMass = ref(null)
            const grade = ref(null)
            const refGrade = ref(null)
            const barcode = ref(null)
            const refBarcode = ref(null)

            const ticket = ref({
                id: null,
                season_id: null,
                buyer_id: null,
                market_id: null,
                sale_id: null,
                mass: null,
                grade_id: null,
                owner_id: null,
                owner_type: null,
                barcode: null,
            })
            
            const CROMIS_STORE_MODULE_NAME = 'cromis-ticket'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, ticketsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-ticket/grades')
                        .then(response => {
                            grades.value = response.data.grades
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                if(client.value.type !== 2){
                    await store.dispatch('cromis-ticket/registrationNumbers')
                                .then(response => {
                                    registrationNumbers.value = response.data.registrationNumbers
                                    console.log(registrationNumbers.value)
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }

                if(client.value.type === 2){
                    await store.dispatch('cromis-ticket/societies')
                                .then(response => {
                                    societies.value = response.data.societies
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }

                await store.dispatch('cromis-ticket/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length > 0){
                                    ticket.value.season_id = seasons.value[0].id
                                    changeSeason(ticket.value.season_id)
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-ticket/buyers')
                            .then(response => {
                                buyers.value = response.data.buyers
                                if(buyers.value.length === 1){
                                    ticket.value.buyer_id = buyers.value[0].id
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-ticket/markets')
                            .then(response => {
                                markets.value = response.data.markets
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                // await store.dispatch('cromis-ticket/sales', { season_id: ticket.value.season_id })
                //             .then(response => {
                //                 sales.value = response.data.sales
                //             })
                //             .catch(error => {
                //                 console.log('got error', error)
                //             })
            })

            const changeSeason = async () => {
                season_id.value = ticket.value.season_id
            }

            const changeBuyer = async () => {
                buyer_id.value = ticket.value.buyer_id
            }

            const changeMarket = async () => {
                market_id.value = ticket.value.market_id

                sale_id.value = null
                ticket.value.sale_id = null

                sales.value.splice(0)

                await store.dispatch('cromis-ticket/sales', { season_id: season_id.value, buyer_id: buyer_id.value, market_id: market_id.value })
                            .then(response => {
                                sales.value = response.data.sales
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const changeSale = async () => {
                sale_id.value = ticket.value.sale_id

                await store.dispatch('cromis-ticket/list', { sale_id: sale_id.value })
                            .then(response => {
                                ourTickets.value = response.data.tickets
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filers
                client,
                season_id,
                buyer_id,
                market_id,
                sale_id,
                saleTickets,
            } = useTicketsList()

            tickets.value = records.value

            const validateGrower = (event) => {
                let found = false
                registrationNumbers.value.map((element) => {
                    if(element.registrationNumber === owner.value){
                        found = true
                        ticket.value.owner_id = element.id
                    }

                })

                if(!found){
                    serverErrors.value = { 'owner_id': ['Grower number not found']}
                    context.refs['refOwner'].focus()
                }
                else{
                    serverErrors.value = null
                    context.refs['refMass'].focus()
                }
            }

            const validateSociety= (event) => {
                if(owner.value === null){
                    serverErrors.value = { 'owner_id': ['Society must be selected']}
                    context.refs['refOwner'].focus()
                }
                else{
                    serverErrors.value = null

                    ticket.value.owner_id = owner.value

                    context.refs['refMass'].focus()
                }
            }

            const validateMass = () => {
                if(ticket.value.mass === null || ticket.value.mass === ''){
                    serverErrors.value = { 'mass': ['Weight required']}
                    context.refs['refMass'].focus()
                }
                else{
                    serverErrors.value = null
                    context.refs['refGrade'].focus()
                }
            }

            const validateGrade = () => {
                let found = false
                
                grades.value.map((item) => {
                    if(grade.value.trim().toUpperCase() === item.name.trim().toUpperCase()){
                        found = true
                        ticket.value.grade_id = item.id
                    }

                })

                if(!found){
                    serverErrors.value = { 'grade_id': ['Invalid grade provided']}
                    context.refs['refGrade'].focus()
                }
                else{
                    serverErrors.value = null
                    grade.value = grade.value.toUpperCase()
                    context.refs['refBarcode'].focus()
                }
            }

            const validateBarcode = () => {
                let found = false

                if(client.value.type !== 2){
                    ourTickets.value.map((item) => {
                        if(item.barcode === barcode.value){
                            found = true
                            ticket.value.id = item.id
                            ticket.value.barcode = item.barcode
                        }

                    })
                }
                else{
                    found = true
                    ticket.value.id = null
                    ticket.value.barcode = barcode.value
                }

                if(!found){
                    serverErrors.value = { 'barcode': ['Ticket was not found']}
                    barcode.value = null
                    context.refs['refBarcode'].focus()
                }
                else{
                    serverErrors.value = null
                    submit()
                }
            }

            const totalCaptured = computed(() => {
                return saleTickets.value.filter((item) => item.govGrade !== null)
            })

            // Form Methods
            const invokeUpdateForm = (item) => {
                serverErrors.value = null

                ticket.value = {
                    id: item.id,
                    season_id: ticket.value.season.id,
                    buyer_id: ticket.value.buyer.id,
                    market_id: ticket.value.market.id,
                    sale_id: ticket.value.sale.id,
                    barcode: null,
                    mass: null,
                    grade_id: null,
                    owner_id: null,
                    owner_type: client.value.type === 2? 2 : 1,
                    barcode: null,
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                serverErrors.value = null

                ticket.value = {
                    id: null,
                    season_id: ticket.value.season_id,
                    buyer_id: ticket.value.buyer_id,
                    market_id: ticket.value.market_id,
                    sale_id: ticket.value.sale_id,
                    barcode: barcode.value,
                    mass: null,
                    grade_id: null,
                    owner_id: null,
                    owner_type: client.value.type === 2? 2 : 1,
                }

                myModal.value.show()
            }

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                if(bvModalEvt !== undefined){
                    bvModalEvt.preventDefault()
                }

                serverErrors.value = null

                ticket.value.barcode = barcode.value

                saleTickets.value.map((item) => {
                    if(item.barcode === barcode.value){
                        ticket.value.id = item.id
                    }
                })

                // Handle form submit
                if (ticket.value.id === null || ticket.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(ticket.value)
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-ticket/create', ticket.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        ticket.value.mass = null
                        grade.value = null
                        barcode.value = null

                        context.refs['refMass'].focus()

                        // myModal.value.hide()

                        // context.root.$swal({
                        //     icon: 'success',
                        //     text: `Purchase agreement registered successfully!`,
                        //     showConfirmButton: true,
                        //     timer: 3000,
                        //     customClass: {
                        //         confirmButton: 'btn btn-primary',
                        //     },
                        //     buttonsStyling: false,
                        // })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-ticket/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        ticket.value.mass = null
                        grade.value = null
                        barcode.value = null

                        context.refs['refMass'].focus()

                        // myModal.value.hide()

                        // context.root.$swal({
                        //     icon: 'success',
                        //     text: `Changes to agreement # ${response.data.number} of ${response.data.sale.market.code} sale ${ response.data.sale.number } has been saved successfully!`,
                        //     showConfirmButton: true,
                        //     timer: 3000,
                        //     customClass: {
                        //         confirmButton: 'btn btn-success',
                        //     },
                        //     buttonsStyling: false,
                        // })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                // await store.dispatch('cromis-ticket/remove', id)
                //             .then(response => {
                //                 refetch()
                //             })
                //             .catch(error => {
                //                 context.root.$swal({
                //                     icon: 'error',
                //                     title: 'Server Error',
                //                     text: 'Something went wrong. See tech support',
                //                     showConfirmButton: true,
                //                     customClass: {
                //                         confirmButton: 'btn btn-danger',
                //                     },
                //                     buttonsStyling: true,
                //                 })
                //             })
            }

            return {
                // Data
                client,
                selections,
                formSelections,
                levels,
                children,
                seasons,
                buyers,
                markets,
                ticket,
                sales,
                societies,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Methods
                required,
                saving,
                serverErrors,
                dataForm,
                form,
                myModal,
                changeSeason,
                changeBuyer,
                changeMarket,
                changeSale,
                invokeCreateForm,
                invokeUpdateForm,
                submit,
                isFormValid,
                handleCreate,
                handleUpdate,
                remove,

                owner,
                refOwner,
                refMass,
                grade,
                refGrade,
                barcode,
                refBarcode,
                validateGrower,
                validateSociety,
                validateMass,
                validateGrade,
                validateBarcode,
                totalCaptured,
                saleTickets,
                ourTickets,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>